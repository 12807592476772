
.custom-navbar {
  background-color: #e0e8f2 !important; /* Set your desired background color */
  padding: 20px 40px; /* Adjust padding as needed */
  border-bottom: 2px solid #e11111; /* Add a border at the bottom */
 
}

.custom-toggler-icon {
  border-color: #000000; /* Set the color of the toggle icon */
}

.navbar-nav{
   margin-left:30rem;
   font-size: 20px;
}

.nav-link{
  margin-left:40px;
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}

.nav-link:hover{

  color: #e11111;
}
/* Full-screen overlay for mobile view */
@media (max-width: 767px) {
  .custom-navbar {
    padding: 10px 15px;
  }

  .navbar-logo {
    max-width: 60px; /* Adjust the maximum width as needed */
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #bfc3c8;
    z-index: 1000;
    padding-top: 70px; /* Adjust padding-top to avoid overlapping with fixed-top navbar */
    overflow-y: auto; /* Enable scrolling if content exceeds the viewport height */
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left:7px;
   
  }

  .nav-link {
    padding: 15px;
    text-align: center;
   
    
  }
}
  
  /* Add a margin to the body to avoid content overlapping with the fixed header */
  body {
    margin-top: 70px;
    font-family: serif; /* Adjust the margin based on the height of your fixed header */
  }


    .bg {
       position: relative;
       max-width: 100%;
      }
  
    .bg img {
       height: 100%;
       width: 100%;
       object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
       opacity: 0.3;
       max-width: 100%;
      }

    .bg h1 {
      font-size: 4rem;
      z-index: 1;
      font-family: 'Courier New, Courier, monospace';
      max-width: 80%;
      font-weight: bold;
      top: 38%;
      left: 50px;
      background: -webkit-linear-gradient(#f00000, #1f1c18);
      background-clip: text;
      -webkit-background-clip:text;
      -webkit-text-fill-color: transparent;
    }

    @media (max-width: 768px) {

      .bg img {
        height: auto; /* Allow the height to adjust based on the image's aspect ratio */
        
      }

      .bg h1 {
        font-size: 1.3rem;
        max-width: 100%;
        left: 0;
        text-align: center;
        top: 30%;
      }
    }


    .Robotic h1 {
      margin: 100px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-size: 3rem;
      text-align: center; /* Align text to center */
    }
    
    /* Media query for screens with a maximum width of 600px (typical for phones) */
    @media only screen and (max-width: 600px) {
      .Robotic h1 {
        margin: 25px; /* Adjust margin for smaller screens */
        font-size: 1rem; /* Reduce font size for smaller screens */
      }
    }
    
  

  
 
  div [class^="col-"]{
    padding-left:5px;
    padding-right:5px;
  }
  .card{
    transition:0.5s;
    cursor:pointer;
  }
  .card-title{  
    font-size:15px;
    transition:1s;
    cursor:pointer;
    font-family: Arial, Helvetica, sans-serif;
  }
  .card-title i{  
    font-size:15px;
    transition:1s;
    cursor:pointer;
    color:#ffa710                                                                                                                 
  }
  .card-title i:hover{                                                                                                                              
    transform: scale(1.25) rotate(100deg);                                                                                          
    color:#18d4ca;
    
  }
  .card:hover{
    transform: scale(1.05);
    box-shadow: 10px 10px 15px rgba(0,0,0,0.3);
  }
  .card-text{
    height:80px;  
  }
  
  .card::before, .card::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale3d(0, 0, 1);                                                                          
    transition: transform .3s ease-out 0s;
    background: rgba(255, 255, 255, 0.1);
    content: '';
    pointer-events: none;
  }
  .card::before {
    transform-origin: left top;
  }
  .card::after {
    transform-origin: right bottom;
  }
  .card:hover::before, .card:hover::after, .card:focus::before, .card:focus::after {
    transform: scale3d(1, 1, 1);
  }

  .img-act{margin-top: 20px;}

  .custom-image {
    max-width: 100%; /* Ensures the image doesn't exceed its container */
    height: 10rem; 
    margin-top: 10px;
  
  }

  .custom-card {height: 35rem;}

    .custom-card img{
    height: 14.8rem;}
  
  .card-block{
    height: 35rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;  
  }

 .services-card{height: 40rem;}


 .services > h1{
 
   color: #181414;
   font-size: 3rem;
   padding-top: 50px;
   padding-bottom: 50px;
   font-weight: bold;
   font-family: Arial, Helvetica, sans-serif;
 


 }

 
 .services-card > img{

  width: 250px;
  margin: auto;
  height: 250px;
 
}


.scroller h1{
  color: #000000;
  font-size: 3rem;
  text-align:center;
  margin: 8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;

}


 @media only screen and (max-width: 600px) {
  .card {
    width: 16rem; /* Make the card width 100% for smaller screens */
     margin: auto;
    margin-top: 50px;
  }
 
  .card img {
    width: 100%; /* Make the image width 100% for smaller screens */
    height: auto; /* Maintain image aspect ratio */
  }
  
  .card-title {
    font-size: 1rem; /* Reduce font size for card titles */
  }
  
  .card-text {
    font-size: 0.7rem; /* Reduce font size for card text */
  }

  /* Flexbox layout for cards container on small screens */
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col-md-3 {
    flex: 0 0 100%; /* Set column width to 100% for small screens */
  }

  .services-card > img{
     width: 10rem;
    margin: auto;
}

   .services-card{
      height: 32rem;
   }

   .services > h1{
      font-size: 20px;
      text-align: center;
      padding-top: 50px;
    }

    .card-block{
      height: 30rem;
    }

    .custom-card {height: 30rem;}

    .scroller h1{
      color: #000000;
      font-size: 1.5rem;
      text-align:center;
      margin: 8rem;
      font-weight: bold;
    
    }
     
  
}




.wrapper{
  width: 1000px;
  height:150px;
  display: flex;
  overflow-x:auto;
  white-space: nowrap;
  margin-left: 30rem;
  margin-top: 100px;
 }

 .scrolling-container {
  display: flex;
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
  
  .wrapper::-webkit-scrollbar{width:0;}

  .wrapper .item img{
  min-width:110px;
  height:110px;
  line-height: 110px;
  text-align:center;
  display: inline-block; /* Display items in a row */
  width: 100px; /* Set the width of each item */ /* Set the height of each item (adjust as needed) */
  margin-right: 50px; /* Optional: Add margin between items */
  margin-left: 100px;
  }

  @media screen and (max-width: 768px) {
    .wrapper {
      margin-left: 0;
      width: 350px;
      margin-left: 0;
    }
  
    .item img {
      width: 70px;
      height: 70px;
      margin-right: 5px;
    }
  }


  
  .footer{
    background: #152F4F;
    color:white;
    
    .links{
      ul {list-style-type: none;}
      li a{
        color: white;
        transition: color .2s;
        &:hover{
          text-decoration:none;
          color:#4180CB;
          }
      }
    }  

    .about-company{
      i{font-size: 25px;}
      a{
        color:white;
        transition: color .2s;
        &:hover{color:#4180CB}
      }
    } 
    .location{
      i{font-size: 18px;}
    }
    .copyright p{border-top:1px solid rgba(255,255,255,.1);} 
  }




 /* style.css */

.about {
  background: rgb(224, 251, 222);
  background: linear-gradient(360deg, rgb(255, 245, 245) 0%, rgb(144, 148, 144) 100%);
  padding: 100px 0 20px 0;
  text-align: center;
}

.about h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.about p {
  font-size: 2rem;
  color: #000000;
  margin: 0 auto;
 font-family: Arial, Helvetica, sans-serif;
}

.about-info {
  margin: 7rem 2rem;
  display: flex;
  flex-direction: column; /* Change to column layout on smaller screens */
  align-items: center;
  justify-content: center;
  text-align: center; /* Center text on smaller screens */
  font-family: Arial, Helvetica, sans-serif;
}

.about-img {
  width: 20rem;
  height: 15rem;
}

.about-img img {
  width: 80%;
  height: 80%;
  border-radius: 5px;
  object-fit: contain;
}

.about-text {
  max-width: 600px; /* Adjust the maximum width based on your design */
}

.about-info p {
  font-size: 1.5rem;
  margin: 0 2rem;
  text-align: justify;
}

.about-img div{
  
  margin-left:500px;
}                                                                                   

/* Add responsive styles using media queries as needed */
@media only screen and (max-width: 600px) {
  .about h1 {
      font-size: 2rem;

  }


  .about-info p {
      font-size: 0.8rem;
     
    
  }
}




